import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonButtons, IonBackButton, IonGrid, IonToast, IonSpinner
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import { functions } from "../../../common/firebase"
import TabBar from '../../../common/TabBar/TabBar';
import "./Credentials.css";

interface Credential {
    key: string,
    title: string,
    link: string,
    username: string,
    password: string,
}

type Props = {
    history: any,
};

type State = {
    credentials: Credential[],
    showRequestedToast: boolean,
    isLoading: boolean,
};

class Credentials extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            credentials: [],
            showRequestedToast: false,

            isLoading: false,
        }
    }

    componentDidMount() {
        const getCredentials = httpsCallable(functions, "getCredentials-getCredentials");
        this.setState({ isLoading: true });
        getCredentials()
        .then(response => {
            console.log(response.data);
            if(response.data && (response.data as any).credentials) {
                const credentialsToReturn: Credential[] = [];
                const credentialsKeys = Object.keys((response.data as any).credentials);
                for (let i = 0; i < credentialsKeys.length; i++) {
                    credentialsToReturn.push({
                        key: credentialsKeys[i],
                        title: (response.data as any).credentials[credentialsKeys[i]].t,
                        link: (response.data as any).credentials[credentialsKeys[i]].l,
                        username: (response.data as any).credentials[credentialsKeys[i]].u,
                        password: (response.data as any).credentials[credentialsKeys[i]].p,
                    });                  
                }
                this.setState({
                    credentials: credentialsToReturn,
                    isLoading: false,
                });
            }
        })
        .catch(err => {
            this.setState({ isLoading: false });
            console.log("[Credentials] error calling company data update:", err);
        })
    }

    render() {
        return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>Credenziali</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonGrid className="credentialsMainGrid">
                    {
                        this.state.isLoading &&
                        <div className="credentialsSpinnerDiv">
                            <IonSpinner />
                        </div>
                    }
                    {
                        !this.state.isLoading &&
                        this.state.credentials.map(credential => {
                            return(
                                <div
                                    key={credential.key}
                                    className="credentialsCredDiv"
                                >
                                    <p className="credentialsTitle">{credential.title}</p>
                                    <a href={credential.link} target="_blank" rel="noopener noreferrer">{credential.link}</a>
                                    <p className="credentialsUsername">{credential.username}</p>
                                    <p className="credentialsPassword">{credential.password}</p>
                                </div>
                            )
                        })
                    }
                </IonGrid>
            </IonContent>
            <TabBar />

            <IonToast
                isOpen={this.state.showRequestedToast}
                message="Credenziali richieste"
                onDidDismiss={() => {
                    this.setState({ showRequestedToast: false });
                }}
                duration={1000}
            />
        </IonPage>
        
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Credentials);