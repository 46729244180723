import React from "react";
import { initializeApp } from 'firebase/app';
import { getAuth, User, initializeAuth, indexedDBLocalPersistence } from 'firebase/auth';
import { getDatabase, ref as dbRef, push} from 'firebase/database';
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { connect } from "react-redux";
import { isPlatform } from "@ionic/core";
import { authActions } from "../modules/authentication/store/actions";

export const firebaseConfig = {
	apiKey: "AIzaSyA-EQe5iO_dIEtZ2SWdRSPoqqqU7Ih5hzU",
	authDomain: "estro-internal.firebaseapp.com",
	databaseURL: "https://estro-internal.firebaseio.com",
	projectId: "estro-internal",
	storageBucket: "estro-internal.appspot.com",
	messagingSenderId: "705447264813",
	appId: "1:705447264813:web:b3c5c40330cedfc7efeea4",
	measurementId: "G-RN7J5BLCJV"
  };

const app = initializeApp(firebaseConfig);
getAnalytics(app);

export const auth =
	isPlatform("ios") ?
	initializeAuth(app, {
		persistence: indexedDBLocalPersistence
	}) :
	getAuth();
export const database = getDatabase();
export const functions = getFunctions(undefined, "europe-west1");
export const storage = getStorage();


type Props = {
  authenticationSuccess: (userData: User) => void,
  /* failed: (message: string) => void, */
};

type State = {
};

class Authentication extends React.Component<Props, State> {

	componentDidMount() {
		auth.onAuthStateChanged(async (userData) => {
			if (userData !== null) {
				console.log("We are authenticated now!");
	
				this.props.authenticationSuccess(userData);
				// get user data 

				push(dbRef(database, `l/${userData.uid}/a`), {
					t: Math.floor((new Date()).getTime()/1000),
				})
				.then(() => {})
				.catch(err => {
					console.log("[firebase] error l. a.", err);
				});
			}
			else {
				console.log("We did not authenticate.");
			}
		});
	}

	render() {
		return (
			<></>
		);
	}
}


const mapDispatchToProps = (dispatch: any) => {
    return {
		authenticationSuccess: (userData: User) => {
			dispatch(authActions.authenticationSuccess(userData));
		},
    }
}

export default connect(null, mapDispatchToProps)(Authentication);