import { Product } from "./reducers";

export function fromProductIdToProductName(productId: string): string {
    switch (String(productId).trim()) {
        case "it.gedu.app.full":
            return "Licenza completa"; /* TO BE LOCALIZED */

        case "it.gedu.app.matchingcards":
            return "Completa la figura"; /* TO BE LOCALIZED */
            
        default:
            break;
    }
    return "";
}

export function doesItHaveAnActiveProduct(products: Product[]): boolean {
    const nowTimestamp = Math.floor((new Date()).getTime()/1000);
    for (let i = 0; i < products.length; i++) {
        if(products[i].expiryTimestamp > nowTimestamp) {
            return true;
        }
    }
    return false;
}

export function isItACustomer(products: Product[]) {
    const nowTimestamp = Math.floor((new Date()).getTime()/1000);
    let filteredProducts = products.filter(product => product.expiryTimestamp > nowTimestamp);
    filteredProducts = products.filter(product => (product.from !== "first-week-from-signup") && (product.from !== "free"));
    return filteredProducts.length > 0;
}