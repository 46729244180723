export function fromMaestroProductIdToProductName(productId: string): string {
    switch (productId) {
        case "prod_L45hosDxiFwR6S":
            return "Maestro premium mensile"

        case "prod_L45pDR5P62kNYl":
            return "Maestro premium mensile"

        case "ai.estro.maestro.aba.firstweekfree":
            return "Maestro premium gratuito per la prima settimana";

        case "ai.estro.maestro.aba.custom":
            return "Maestro premium licenza personalizzata";

        case "ai.estro.maestro.aba.free":
            return "Maestro premium licenza gratuita assegnata dal team estro";

        case "ai.estro.maestro.aba.monthly":
            return "Maestro premium mensile";
    
        default:
            return "Maestro"
    }
}