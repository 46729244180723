import React from 'react';
import {
    IonTabBar, IonTabButton, IonIcon, IonLabel,
} from '@ionic/react';
import { apps, person, flask, key, shapes } from 'ionicons/icons'
import { connect } from 'react-redux';

type Props = {
    history?: any,
}

class TabBar extends React.Component<Props> {
    render() {
        return (
            <IonTabBar slot="bottom">

                <IonTabButton tab="maestro" href="/maestro">
                    <IonIcon icon={apps} />
                    <IonLabel>Maestro</IonLabel> {/* TO LOCALIZE THIS */}
                </IonTabButton>

                <IonTabButton tab="gedu" href="/gedu">
                    <IonIcon icon={shapes} />
                    <IonLabel>Gedu</IonLabel> {/* TO BE LOCALIZED */}
                </IonTabButton>

                <IonTabButton tab="credentials" href="/credentials">
                    <IonIcon icon={key} />
                    <IonLabel>Credenziali</IonLabel> {/* TO LOCALIZE THIS */}
                </IonTabButton>

                <IonTabButton tab="profile" href="/profile">
                    <IonIcon icon={person} />
                    <IonLabel>Profilo</IonLabel> {/* TO LOCALIZE THIS */}
                </IonTabButton>

            </IonTabBar>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(TabBar);