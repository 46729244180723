import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonButtons, IonBackButton, IonGrid, IonButton
} from '@ionic/react';
import { get, ref } from 'firebase/database';
import React from 'react';
import { connect } from 'react-redux';
import { auth, database } from "../../../common/firebase"
import TabBar from '../../../common/TabBar/TabBar';
import { authActions } from '../../authentication/store/actions';
import "./Profile.css";

interface UserProfile {
    n: string,
    s: string,
    e: string,
    r: string,
}

type Props = {
    history: any,

    logout: () => void,
};

type State = {
    user: UserProfile | null
};

class Profile extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            user: null,
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(user => {
            if(user) {
                get(ref(database, `/users/${user.uid}`))
                .then(data => {
                    if(data.val()) {
                        this.setState({ user: data.val() });
                    }
                })
                .catch(err => {
                    console.log("[Profile] Error fetching user data:", err);
                });
            }
        })
    }

    render() {
        return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                    <IonTitle>Profilo</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonGrid className="profileGrid">
                    {
                        this.state.user &&
                        <div className="profileMainDiv">
                            <p className="profileNameSurnameParagraph">
                                {this.state.user.n}&nbsp;{this.state.user.s}
                            </p>

                            <p className="profileRoleParagraph">
                                {this.state.user.r}
                            </p>

                            <p className="profileEmailParagraph">
                                {this.state.user.e}
                            </p>
                        </div>
                    }

                    <div className="profileLogoutDiv">
                        <IonButton
                            fill="outline"
                            color="medium"
                            onClick={() => {
                                this.props.logout();
                                setTimeout(() => {
                                    this.props.history.replace("/");
                                }, 1000);
                            }}
                        >
                            Logout
                        </IonButton>
                    </div>
                </IonGrid>
            
            </IonContent>

            <TabBar />
        </IonPage>
        
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        logout: () => {
            dispatch(authActions.logout());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);