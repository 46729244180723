import { Redirect, Route, Switch } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { store } from './common/store';

import Login from './modules/authentication/pages/Login';
import MaestroHome from './modules/maestro/pages/MaestroHome';
import Profile from './modules/profile/pages/Profile';
import Credentials from './modules/credentials/pages/Credentials';
import GeduHome from './modules/gedu/pages/GeduHome';
import MaestroUser from './modules/maestro/pages/MaestroUser';
import GeduUserDetails from './modules/gedu/pages/GeduUserDetails';

function PrivateRoute({ ...rest }: any) {
    let isAuthenticated = store.getState().auth.userData ? true : false;
    console.log("isAuthenticated", isAuthenticated);
    return (
        <Route
            {...rest}
            render={isAuthenticated ? rest['render'] : () => <Redirect to="/authentication/login" />}
        />
    );
}

function Router() {
    return (
        <IonReactRouter>
            <IonRouterOutlet>
                <Switch>
                    <Route path="/authentication/login" component={Login} />

                    <PrivateRoute
                        exact path="/maestro"
                        render={(routeProps: any) => <MaestroHome  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/maestro/users"
                        render={(routeProps: any) => <MaestroUser  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/gedu"
                        render={(routeProps: any) => <GeduHome  {...routeProps} />}
                    />
                    <PrivateRoute
                        exact path="/gedu/user"
                        render={(routeProps: any) => <GeduUserDetails  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/credentials"
                        render={(routeProps: any) => <Credentials  {...routeProps} />}
                    />

                    <PrivateRoute
                        exact path="/profile"
                        render={(routeProps: any) => <Profile  {...routeProps} />}
                    />


                    <Redirect to="/authentication/login" />

                </Switch>
            </IonRouterOutlet>
        </IonReactRouter>
    );
}

export default Router;