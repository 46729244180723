import React from 'react';
import {
  IonApp,
} from '@ionic/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './common/store';
import Authentication from './common/firebase';
import Router from './Router';

class App extends React.Component {
	render() {
		return (
			<StoreProvider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<IonApp>
						<Authentication />
						<Router />
					</IonApp>
				</PersistGate>
			</StoreProvider>
		);
	};
}

export default App;