export function action2string(action: number): string {
    switch (action) {
        case 0:
            return 'Tutorial Started'
            break;
        case 1:
            return 'Next step'
            break;
        case 2:
            return 'Previous Step'
            break;
        case 3:
            return 'Tutorial Closed'
            break;
        case 4:
            return 'Tutorial Skipped'
            break;
        case 5:
            return 'Tutorial Finished'
            break;
        case 99:
            return 'Tutorial not shown'
            break;
        default:
            return ''
            break;
    }
}

export function step2string(step: number): string {
    switch (step) {
        case 1:
            return 'Lista delle task'
            break;
        case 2:
            return 'Pulsante delle sessioni'
            break;
        case 3:
            return 'Pulsante del grafico'
            break;
        case 4:
            return 'Pulsante presa dati'
            break;
        case 5:
            return 'Scheda di lavoro'
            break;
        case 6:
            return 'Barra di navigazione'
            break;
        case 7:
            return 'Lista delle sessioni'
            break;
        case 8:
            return 'Pulsante del grafico'
            break;
        case 9:
            return 'Filtro per sessione'
            break;
        case 10:
            return 'Lista degli utenti'
            break;
        case 11:
            return 'Strumenti del grafico'
            break;
        case 12:
            return 'Esporta immagine del grafico'
            break;
        case 13:
            return 'Esporta dati del grafico'
            break;
        case 14:
            return 'Grafico dei dati'
            break;
        case 15:
            return 'Lista degli step'
            break;
        case 16:
            return 'Valore dello step'
            break;
        case 17:
            return 'Contenuto media'
            break;
        case 18:
            return 'Fine sessione'
            break;
        case 19:
            return 'Comportamenti problema'
            break;
        case 20:
            return 'Le task della scheda'
            break;
        case 21:
            return 'Video modeling'
            break;
        case 22:
            return 'Modifica la task'
            break;
        case 23:
            return 'Aggiungi task'
            break;
        case 24:
            return 'Titolo della task'
            break;
        case 25:
            return 'Modalità di raccolta dati'
            break;
        case 26:
            return 'Lista degli step'
            break;
        case 27:
            return 'Riordina gli step'
            break;
        case 28:
            return 'Lista delle schede'
            break;
        case 29:
            return 'Aggiungi scheda'
            break;
        case 30:
            return 'Informazioni generali'
            break;
        case 31:
            return 'Lista accessi'
            break;
        case 32:
            return 'Comportamenti problema'
            break;
        case 33:
            return 'Informazioni profilo'
            break;
        case 34:
            return 'Gestisci abbonamento'
            break;
        case 35:
            return 'I tuoi dispositivi'
            break;
        case 36:
            return 'Feedback e assistenza'
            break;
        default:
            return ''
            break;
    }
}

