import {
    IonBackButton, IonButton, IonButtons, IonCard, IonCardContent,
    IonCardHeader, IonCardTitle, IonContent, IonDatetime, IonGrid, IonHeader,
    IonInput,
    IonLoading, IonModal, IonPage, IonSpinner,
    IonTitle, IonToast, IonToolbar,
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import { functions } from '../../../common/firebase';
import { GeduUserData } from '../store/reducers';
import { fromProductIdToProductName } from '../store/services';
import "./GeduUserDetails.css";

type Props = {
    history: any,
};

type State = {    
    userDbInfo: GeduUserData | null,
    isLoadingUserDbInfo: boolean,

    licenseType: number | null,
    licenseExpiryDate: number | null,
    nowDate: Date,
    fiveYearsFromNow: Date,
    isDateModalOpen: boolean,

    isLoading: boolean,

    notificationTitle: string | null,
    notificationBody: string | null,
    notificationCallToActionUrl: string | null,
    showNotificationSuccessToast: boolean,
};

class GeduUserDetails extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);

        const nowDate = new Date();
        const fiveYearsFromNow = new Date(nowDate.getFullYear() + 5, nowDate.getMonth(), nowDate.getDate());

        this.state = {
            userDbInfo: null,
            isLoadingUserDbInfo: false,

            licenseType: null,
            licenseExpiryDate: null,
            nowDate: nowDate,
            fiveYearsFromNow: fiveYearsFromNow,
            isDateModalOpen: false,

            isLoading: false,

            notificationTitle: null,
            notificationBody: null,
            notificationCallToActionUrl: null,
            showNotificationSuccessToast: false,
        }
    }

    componentDidMount() {
        const url = new URL(window.location.href);
        if(url.searchParams.get("id")) {
            this.setState({ isLoadingUserDbInfo: true });

            const getGeduUser = httpsCallable(functions, "getGeduUser-getGeduUser");
            getGeduUser({
                userUuid: url.searchParams.get("id"),
            })
            .then((res) => {
                if((res.data as any).user ) {
                    this.setState({ userDbInfo: (res.data as any).user as GeduUserData});
                }
                else {
                    console.error("[GeduUserDetails] error returned from getGeduUser cloud function:", (res.data as any).error);
                }
                this.setState({ isLoadingUserDbInfo: false });
            })
            .catch(err => {
                console.error("[GeduUserDetails] error calling getGeduUser cloud function:", err);
                this.setState({ isLoadingUserDbInfo: false });
            })
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton
                                defaultHref="/gedu"
                            />
                        </IonButtons>
                        <IonTitle>
                            Dettaglio utente {/* TO BE LOCALIZED */}
                        </IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonGrid className="geduUserDetailsMainGrid">

                        {
                            this.state.isLoadingUserDbInfo &&
                            <div>
                                <IonSpinner />
                            </div>
                        }

                        {
                            !this.state.userDbInfo &&
                            !this.state.isLoadingUserDbInfo &&
                            <div>
                                <p>
                                    Nessun utente selezionato o utente non esistente.
                                </p>
                            </div>
                        }

                        {
                            this.state.userDbInfo &&
                            <>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            Dati utente {/* TO BE LOCALIZED */}
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <div>
                                            <p>
                                                Email: <b>{this.state.userDbInfo.email}</b> {/* TO BE LOCALIZED */}
                                            </p>
                                        </div>
                                    </IonCardContent>
                                </IonCard>

                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>
                                            Accessi
                                        </IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <div>
                                            {
                                                this.state.userDbInfo.accesses.map(access => { return (
                                                    <div
                                                        key={access.timestamp}
                                                    >
                                                        {new Date(access.timestamp*1000).toLocaleString()}
                                                    </div>
                                                )})
                                            }
                                        </div>
                                    </IonCardContent>
                                </IonCard>

                                {
                                    this.state.userDbInfo.products.length > 0 &&
                                    <IonCard>
                                        <IonCardHeader>
                                            <IonCardTitle>
                                                Prodotti assegnati {/* TO BE LOCALIZED */}
                                            </IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            {
                                                this.state.userDbInfo.products.map(product => { return (
                                                    <div
                                                        key={product.uuid}
                                                        className="organizationDetailProductItemDiv"
                                                    >
                                                        <p className="organizationDetailProductItemName">
                                                            {fromProductIdToProductName(product.productId)}
                                                        </p>

                                                        <p>
                                                            Ottenuta il: {(new Date(product.startTimestamp*1000)).toLocaleDateString()} alle {(new Date(product.startTimestamp*1000)).toLocaleTimeString()}.
                                                        </p>
                                                        <p>
                                                            Scadenza il: {(new Date(product.expiryTimestamp*1000)).toLocaleDateString()} alle {(new Date(product.expiryTimestamp*1000)).toLocaleTimeString()}.
                                                        </p>
                                                    </div>
                                                )})
                                            }
                                        </IonCardContent>
                                    </IonCard>
                                }

                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>Notification sending</IonCardTitle> {/* TO LOCALIZE THIS */}
                                    </IonCardHeader>

                                    <IonCardContent>

                                        <IonInput
                                            placeholder="Title" /* TO LOCALIZE THIS */
                                            value={this.state.notificationTitle}
                                            onIonChange={(e) => {
                                                this.setState({ notificationTitle: e.detail.value ? e.detail.value : null });
                                            }}
                                        />

                                        <IonInput
                                            placeholder="Body" /* TO LOCALIZE THIS */
                                            value={this.state.notificationBody}
                                            onIonChange={(e) => {
                                                this.setState({ notificationBody: e.detail.value ? e.detail.value : null });
                                            }}
                                        />

                                        <IonInput
                                            placeholder="Call to action url" /* TO LOCALIZE THIS */
                                            value={this.state.notificationCallToActionUrl}
                                            onIonChange={(e) => {
                                                this.setState({ notificationCallToActionUrl: e.detail.value ? e.detail.value : null });
                                            }}
                                        />

                                        <div className="maestroHomeCardCenteredButtonDiv">
                                            <IonButton
                                                disabled={
                                                    !this.state.userDbInfo ||
                                                    !this.state.notificationTitle ||
                                                    !this.state.notificationBody
                                                }
                                                onClick={() => {
                                                    const sendGeduUserNotification = httpsCallable(functions, "sendGeduUserNotification-sendGeduUserNotification");
                                                    sendGeduUserNotification({
                                                        notificationUserUuid: this.state.userDbInfo?.uuid,
                                                        notificationTitle: this.state.notificationTitle,
                                                        notificationBody: this.state.notificationBody,
                                                        notificationCallToActionUrl: this.state.notificationCallToActionUrl,
                                                    })
                                                        .then(data => {
                                                            console.log(data.data);
                                                            if (!(data.data as any).error) {
                                                                this.setState({ showNotificationSuccessToast: true });
                                                            }
                                                        })
                                                        .catch(err => {
                                                            console.log("[Maestro home] error calling company data update:", err);
                                                        })

                                                }}
                                            >
                                                Invia {/* TO LOCALIZE THIS */}
                                            </IonButton>
                                        </div>
                                    </IonCardContent>
                                </IonCard>

                            </>
                        }
                        
                        
                    </IonGrid>
                </IonContent>

                <IonModal
                    isOpen={this.state.isDateModalOpen}
                    onDidDismiss={() => {
                        this.setState({ isDateModalOpen: false });
                    }}
                >
                    <IonContent>
                        <IonDatetime
                            min={this.state.nowDate.toISOString().split("T")[0]}
                            max={this.state.fiveYearsFromNow.toISOString().split("T")[0]}
                            onIonChange={e => {
                                this.setState({ licenseExpiryDate: e.detail.value ? Math.floor((new Date(e.detail.value)).getTime()/1000) : null });
                            }}
                        />

                        <IonButton
                            onClick={() => {
                                this.setState({ isDateModalOpen: false });
                            }}
                        >
                            Fatto {/* TO BE LOCALIZED */}
                        </IonButton>
                        
                    </IonContent>
                </IonModal>

                <IonLoading
                    isOpen={this.state.isLoading}
                />

                <IonToast
                    isOpen={this.state.showNotificationSuccessToast}
                    message="Notifica inviata ✅"
                    onDidDismiss={() => {
                        this.setState({ showNotificationSuccessToast: false });
                    }}
                    duration={1000}
                />
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeduUserDetails);