import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonButtons, IonBackButton, IonGrid, IonInput, IonRow,
    IonButton, IonList, IonItem, IonLabel,
} from '@ionic/react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { auth } from '../../../common/firebase';
import "./Login.css";

type LoginProps = {
    history: any,
    isAuthenticated: boolean,

    /* failed: (message: string) => void, */
};

type State = {
    error: boolean,
    email: string,
    password: string,
};

class Login extends React.Component<LoginProps, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            error: true,
            email: '',
            password: '',
        }
    }


    validateEmail(email: string | undefined | null) {
        if (email) {
            let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            let error = !regex.test(email);
            this.setState({ email, error });
        }
        else {
            this.setState({
                email: '',
                error: true,
            })
        }
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton />
                        </IonButtons>
                        <IonTitle>Accedi</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonGrid className="loginMainGrid">
                        <IonList lines='none' >

                            <IonItem
                                className='businessListItem'
                            >
                                <IonLabel
                                    position='floating'
                                >
                                    Email
                                </IonLabel>
                                <IonInput
                                    inputMode='email'
                                    type='email'
                                    autocomplete='email'
                                    onIonChange={(e) => this.validateEmail(e.detail.value)}
                                />
                            </IonItem>

                            <IonItem
                                className='businessListItem'
                            >
                                <IonLabel
                                    position='floating'
                                >
                                    Password
                                </IonLabel>
                                <IonInput
                                    placeholder='Password (almeno 8 caratteri)'
                                    inputmode='text'
                                    type='password'
                                    onIonChange={(e) => {
                                        if (e) {
                                            this.setState({ password: e.detail.value! })
                                        }
                                    }}
                                />
                            </IonItem>

                        </IonList>

                        <IonGrid className='businessSignUpButtonGrid'>
                            <IonRow>
                                <IonButton
                                    disabled={
                                        this.state.error ||
                                        (this.state.password.length < 8) ||
                                        (this.state.email === '')
                                    }
                                    onClick={() => {
                                        signInWithEmailAndPassword(auth, this.state.email, this.state.password)
                                            .then(data => {
                                                console.log("[Login] Signin then:", JSON.stringify(data))
                                            })
                                            .catch(err => {
                                                console.log("[Login] loging error:", JSON.stringify(err));
                                                /* this.props.failed(err.message); */
                                            })
                                    }}
                                >
                                    Accedi
                                </IonButton>
                            </IonRow>
                        </IonGrid>
                    </IonGrid>

                </IonContent>

                {
                    this.props.isAuthenticated && <Redirect to="/maestro" />
                }
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isAuthenticated: state.auth.userData ? true : false
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);