import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonButtons, IonBackButton, IonGrid, IonCard, IonCardContent,
    IonCardHeader, IonCardTitle, IonInput, IonButton, IonLoading,
    IonToast, IonSelect, IonSelectOption, IonDatetime, IonSpinner, IonCheckbox,
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import { auth, functions } from "../../../common/firebase"
import TabBar from '../../../common/TabBar/TabBar';
import { GeduUserData } from '../store/reducers';
import { isItACustomer } from '../store/services';
import "./GeduHome.css";

type Props = {
    history: any,
};

type State = {
    geduUsers: GeduUserData[],
    isLoadingGeduUsersData: boolean,
    justShowCustomers: boolean,

    geduUserToAssignLicenseEmail: string | null,
    licenseType: number | null,
    licenseExpiryDatestring: string | null,
    licenseExpiryTimestamp: number | null,

    isAssigningGeduLicense: boolean,
    showGeduLicenseAssignmentResult: boolean,
    geduLicenseAssignmentMessage: string | null,
};

class GeduHome extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            geduUsers: [],
            isLoadingGeduUsersData: false,
            justShowCustomers: false,

            geduUserToAssignLicenseEmail: null,
            licenseType: null,
            licenseExpiryDatestring: null,
            licenseExpiryTimestamp: null,

            isAssigningGeduLicense: false,
            showGeduLicenseAssignmentResult: false,
            geduLicenseAssignmentMessage: null,
        }
    }

    componentDidMount() {
        auth.onAuthStateChanged(userData => {
            if(userData) {
                this.setState({ isLoadingGeduUsersData: true });
                const getGeduUsersInfo = httpsCallable(functions, "getGeduUsersInfo-getGeduUsersInfo");
                getGeduUsersInfo()
                .then(res => {
                    if((res.data as any).users) {
                        this.setState({ geduUsers: (res.data as any).users as GeduUserData[]});
                    }
                    else {
                        console.error("[GeduHome] error returned by getGeduUsersInfo cloud function:", res.data);
                    }
                    this.setState({ isLoadingGeduUsersData: false });
                })
                .catch(err => {
                    console.error("[GeduHome] error calling getGeduUsersInfo cloud function:", err);
                    this.setState({ isLoadingGeduUsersData: false });
                })
            }
        })
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton />
                        </IonButtons>
                        <IonTitle>Gedu</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className="geduHomeMainGrid">

                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>
                                    Assegnazione licenze GEDU {/* TO BE LOCALIZED */}
                                </IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>

                                <IonInput
                                    placeholder="user account email"
                                    type="email"
                                    value={this.state.geduUserToAssignLicenseEmail}
                                    onIonChange={(e) => {
                                        this.setState({ geduUserToAssignLicenseEmail: e.detail.value ? e.detail.value : null });
                                    }}

                                />

                                <IonSelect
                                    /* value={this.state.notificationFcm} */
                                    className='geduSelectLicense'
                                    placeholder="Seleziona tipologia di licenza" /* TO LOCALIZE THIS */
                                    okText="Okay"
                                    cancelText="Dismiss"
                                    value={this.state.licenseType}
                                    onIonChange={e => {
                                        this.setState({ licenseType: e.detail.value ? Number(e.detail.value) : null });
                                    }}
                                >
                                    <IonSelectOption
                                        value={-1}
                                    >
                                        Prima settimana gratuita
                                    </IonSelectOption>
                                    <IonSelectOption
                                        value={3}
                                    >
                                        Gratuita assegnata dal team estro (✨)
                                    </IonSelectOption>
                                    <IonSelectOption
                                        value={4}
                                    >
                                        Bonifico
                                    </IonSelectOption>
                                    <IonSelectOption
                                        value={5}
                                    >
                                        POS
                                    </IonSelectOption>

                                </IonSelect>

                                <IonDatetime
                                    placeholder='Expiry date'
                                    pickerFormat="DD-MMMM-YYYY orario HH:mm"
                                    max="2025-01-01"
                                    min="2022-02-01"
                                    value={this.state.licenseExpiryDatestring}
                                    onIonChange={e => {
                                        this.setState({ licenseExpiryDatestring: e.detail.value ? e.detail.value : null });

                                        if (e.detail.value) {
                                            this.setState({ licenseExpiryTimestamp: Math.floor((new Date(e.detail.value)).getTime() / 1000) })
                                        }
                                        else {
                                            this.setState({ licenseExpiryTimestamp: null });
                                        }

                                    }}
                                />

                                <IonButton
                                    disabled={
                                        !this.state.geduUserToAssignLicenseEmail
                                    }
                                    onClick={() => {
                                        this.setState({ isAssigningGeduLicense: true });
                                        const assignGeduLicense = httpsCallable(functions, "assignGeduLicense-assignGeduLicense");
                                        assignGeduLicense({
                                            email: this.state.geduUserToAssignLicenseEmail,
                                            licenseType: this.state.licenseType,
                                            licenseExpiryTimestamp: this.state.licenseExpiryTimestamp,
                                        })
                                            .then(res => {
                                                this.setState({ isAssigningGeduLicense: false });
                                                if ((res.data as any).code === 201) {
                                                    this.setState({
                                                        geduLicenseAssignmentMessage: "Licenza assegnata ✅",
                                                        showGeduLicenseAssignmentResult: true,
                                                        geduUserToAssignLicenseEmail: null,
                                                        licenseExpiryDatestring: null,
                                                        licenseExpiryTimestamp: null,
                                                        licenseType: null,
                                                    });
                                                }
                                                else {
                                                    this.setState({
                                                        geduLicenseAssignmentMessage: "Errore nell'assegnare la licenza ❌",
                                                        showGeduLicenseAssignmentResult: true,
                                                    });
                                                }

                                                console.log("[GeduHome] assign gedu license response:", res.data);
                                            })
                                            .catch(err => {
                                                console.error("[GeduHome] assignin gedu license:", err);
                                                this.setState({
                                                    isAssigningGeduLicense: false,
                                                    geduLicenseAssignmentMessage: "Errore nel chiamare la cloud function ❌",
                                                    showGeduLicenseAssignmentResult: true,
                                                });
                                            });
                                    }}
                                >
                                    Assegna licenza {/* TO BE LOCALIZED */}
                                </IonButton>
                            </IonCardContent>
                        </IonCard>

                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>
                                    GEDU users {/* TO BE LOCALIZED */}
                                </IonCardTitle>
                            </IonCardHeader>

                            <IonCardContent>
                                <div className="geduHomeJustShowCustomersDiv">
                                    <IonCheckbox
                                        checked={this.state.justShowCustomers}
                                        onIonChange={(e) => {
                                            this.setState({ justShowCustomers: e.detail.checked });
                                        }}
                                    />
                                    <p>
                                        Mostra solo i clienti {/* TO BE LOCALIZED */}
                                    </p>
                                </div>
                                <div>
                                    {
                                        this.state.geduUsers
                                        .filter(geduUser => (this.state.justShowCustomers ? isItACustomer(geduUser.products) : true))
                                        .map(geduUser => { return (
                                            <div
                                                key={geduUser.uuid}
                                                className="geduHomeUsersUserRowDiv"
                                                title={geduUser.uuid}
                                                onClick={() => {
                                                    this.props.history.push(`/gedu/user?id=${geduUser.uuid}`)
                                                }}
                                            >
                                                <p className="geduHomeUsersUserEmailParagraph">
                                                    {geduUser.email}
                                                </p>
                                                <p>
                                                    {geduUser.accesses.length}
                                                </p>
                                                <p>
                                                    {
                                                        isItACustomer(geduUser.products) &&
                                                        <span>&nbsp;&nbsp;💳</span>
                                                    }
                                                </p>
                                            </div>
                                        )})
                                    }
                                </div>

                                {
                                    this.state.isLoadingGeduUsersData &&
                                    <div className="geduHomeIsLoadingGeduUsersDataDiv">
                                        <IonSpinner />
                                    </div>
                                }

                            </IonCardContent>
                        </IonCard>

                    </IonGrid>
                </IonContent>

                <IonLoading
                    isOpen={this.state.isAssigningGeduLicense}
                    onDidDismiss={() => {
                        this.setState({ isAssigningGeduLicense: false });
                    }}
                    message={"Assegnazione della licenza in corso..."} /* TO LOCALIZE THIS */
                    duration={10000}
                />

                <IonToast
                    isOpen={this.state.showGeduLicenseAssignmentResult}
                    message={this.state.geduLicenseAssignmentMessage ? this.state.geduLicenseAssignmentMessage : ""}
                    onDidDismiss={() => {
                        this.setState({
                            showGeduLicenseAssignmentResult: false,
                            geduLicenseAssignmentMessage: null,
                        });
                    }}
                    duration={1000}
                />

                <TabBar />

            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeduHome);