import {
    IonContent, IonTitle, IonPage, IonToolbar, IonHeader,
    IonButtons, IonBackButton, IonGrid, IonInput, IonButton,
    IonToast, IonCard, IonCardHeader, IonCardTitle, IonCardContent,
    IonSelect, IonSelectOption, IonDatetime, IonPopover, IonList, IonItem
} from '@ionic/react';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import { functions } from "../../../common/firebase"
import TabBar from '../../../common/TabBar/TabBar';
import "./MaestroHome.css";

interface UserToContact {
    uuid: string,
    email: string,
    devicesFcm: string[],
}
interface Access {
    timestamp: number,
    version: string,
    device?: DeviceInfo,
}

interface DeviceInfo {
    browser: string,
    mobile?: string,
    os: string,
    type: 'string',
}

interface UserInfo {
    uuid: string,
    email?: string,
    firstName?: string,
    lastName?: string,
    role?: string,
    accesses: Access[],
    lastAccess: Access,
}

type Props = {
    history: any,
};

type State = {
    notificationUsersToContact: UserToContact[],
    notificationUserUuid: string | null,
    notificationTitle: string | null,
    notificationBody: string | null,
    notificationCallToActionUrl: string | null,
    showNotificationSuccessToast: boolean,

    usersInfo: UserInfo[],
    dateDescending: boolean,

    licenseUserUuid: string | null,
    licenseType: number | null,
    licenseExpiryDatestring: string | null,
    licenseExpiryTimestamp: number | null,
    showLicenseSuccessToast: boolean,

    showAccessesPopover: boolean,
    selectedUser: UserInfo | null,

};

class MaestroHome extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            notificationUsersToContact: [],
            notificationUserUuid: null,
            notificationTitle: null,
            notificationBody: null,
            notificationCallToActionUrl: null,
            showNotificationSuccessToast: false,

            usersInfo: [],
            dateDescending: true,

            licenseUserUuid: null,
            licenseType: null,
            licenseExpiryDatestring: null,
            licenseExpiryTimestamp: null,
            showLicenseSuccessToast: false,

            showAccessesPopover: false,
            selectedUser: null,


        }
    }

    componentDidMount() {
        const getMaestroUsersInfo = httpsCallable(functions, "getMaestroUsersInfo-getMaestroUsersInfo");
        getMaestroUsersInfo()
            .then(data => {
                console.log(data.data);
                let users = (data.data as any).users
                if ((data.data as any).users) {
                    (data.data as any).users.forEach((user: any) => {
                        user.accesses.sort((a: any, b: any) => { return b - a })
                        user.lastAccess = user.accesses[0];
                    })
                    this.sortUserList(users)
                    this.setState({ usersInfo: (data.data as any).users });

                }
            })
            .catch(err => {
                console.log("[Maestro home] error calling get user to contact:", err);
            });

        const getUsersToContact = httpsCallable(functions, "getUsersToContact-getUsersToContact");
        getUsersToContact()
            .then(data => {
                console.log(data.data);
                if ((data.data as any).usersToContact) {
                    this.setState({ notificationUsersToContact: (data.data as any).usersToContact });
                }
            })
            .catch(err => {
                console.log("[Maestro home] error calling get user to contact:", err);
            });
        console.log("ENDED MOUNT FUNCTION", this.state.usersInfo);

        if (this.state.usersInfo)
            this.sortUserAccesses()

    }

    sortUserAccesses() {
        this.state.usersInfo.forEach(user => {
            user.accesses.sort((a, b) => { return a.timestamp - b.timestamp })
        })
    }

    sortUserList(userList: any) {
        //console.log("ORDERING", this.state.dateDescending);

        if (this.state.dateDescending)
            userList.sort(({ lastAccess: a }: any, { lastAccess: b }: any) => b?.timestamp - a?.timestamp);
        else
            userList.sort(({ lastAccess: a }: any, { lastAccess: b }: any) => a?.timestamp - b?.timestamp);

        this.setState({ usersInfo: userList });
        console.log(this.state.usersInfo);

    }

    getFullDateFromTimestamp(timestamp: number) {
        let date = new Date(timestamp * 1000)
        return (
            date.toLocaleDateString() + " - " +
            date.getHours() + ":" +
            date.getMinutes() + ":" +
            date.getSeconds()
        )
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <IonBackButton />
                        </IonButtons>
                        <IonTitle>Home</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className="maestroHomeMainGrid">

                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Maestro users</IonCardTitle> {/* TO LOCALIZE THIS */}
                                <div className="maestroHomeCardCenteredButtonDiv">
                                    <IonButton
                                        onClick={() => {
                                            this.setState({ dateDescending: !this.state.dateDescending }, () => {
                                                this.sortUserList(this.state.usersInfo);
                                            });
                                        }}
                                    >
                                        {this.state.dateDescending ? "Più Recente" : "Meno recente"}
                                    </IonButton>
                                </div>
                            </IonCardHeader>

                            <IonCardContent>
                                <div className="maestroHomeUsersWrapUpDiv">
                                    <p>
                                        Numero di utenti: <b>{this.state.usersInfo.length}</b>.
                                    </p>
                                    <p>
                                        Numero di utenti che hanno registrato almeno un device: <b>{this.state.notificationUsersToContact.length}</b>.
                                    </p>
                                </div>

                                <div className="maestroHomeUsersInfoDiv">
                                    {
                                        this.state.usersInfo.map(user => {
                                            return (
                                                <div
                                                    onClick={() => {
                                                        this.setState({ showAccessesPopover: true, selectedUser: user })
                                                        this.props.history.push(`/maestro/users/?userUuid=${user.uuid}`)
                                                    }}
                                                    key={user.uuid}
                                                    className="maestroHomeUserInfoRow"
                                                >
                                                    <p className="maestroHomeUserEmailParagraph">{user.email ? user.email : "-"}</p>
                                                    <p className="maestroHomeUserInfoParagraph">{user.firstName ? user.firstName : "-"}</p>
                                                    <p className="maestroHomeUserInfoParagraph">{user.lastName ? user.lastName : "-"}</p>
                                                    <p className="maestroHomeUserInfoParagraph">{user.role ? user.role : "-"}</p>
                                                    <p className="maestroHomeUserInfoParagraph">{user.accesses.length}</p>
                                                    <p className="maestroHomeUserInfoParagraph">{user.accesses.length > 0 ? (new Date(user.lastAccess.timestamp * 1000).toLocaleDateString()) : "-"}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </IonCardContent>
                        </IonCard>
                        <IonPopover
                            cssClass='maestroHomeAccessesPopover'
                            onDidDismiss={() => this.setState({ showAccessesPopover: false, selectedUser: null })}
                            isOpen={this.state.showAccessesPopover}>
                            <div>
                                <p>{this.state.selectedUser?.email}</p>
                                <IonList>
                                    {this.state.selectedUser?.accesses.map((access, key) => {
                                        return (
                                            access.device ?
                                                <IonItem key={key}>
                                                    <p className='maestroHomeUserInfoParagraph'>{this.getFullDateFromTimestamp(access.timestamp)}</p>
                                                    <p className='maestroHomeUserInfoParagraph'>{access.device?.browser}</p>
                                                    <p className='maestroHomeUserInfoParagraph'>{access.device?.mobile}</p>
                                                    <p className='maestroHomeUserInfoParagraph'>{access.device?.os}</p>
                                                    <p className='maestroHomeUserInfoParagraph'>{access.device?.type}</p>
                                                </IonItem>
                                                :
                                                <IonItem>
                                                    <p style={{ textAlign: 'center' }} className='maestroHomeUserInfoParagraph'>{this.getFullDateFromTimestamp(access.timestamp)}</p>
                                                </IonItem>
                                        )
                                    })}
                                </IonList>
                            </div>
                        </IonPopover>


                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Notification sending</IonCardTitle> {/* TO LOCALIZE THIS */}
                            </IonCardHeader>

                            <IonCardContent>
                                <IonSelect
                                    /* value={this.state.notificationFcm} */
                                    placeholder="Seleziona utente da contattare" /* TO LOCALIZE THIS */
                                    okText="Okay"
                                    cancelText="Dismiss"
                                    onIonChange={e => {
                                        this.setState({ notificationUserUuid: e.detail.value });
                                    }}
                                >
                                    {
                                        this.state.notificationUsersToContact.map(contact => {
                                            return (
                                                <IonSelectOption
                                                    key={contact.uuid}
                                                    value={contact.uuid}
                                                >
                                                    {contact.email}
                                                </IonSelectOption>
                                            )
                                        })
                                    }
                                </IonSelect>

                                <IonInput
                                    placeholder="Title" /* TO LOCALIZE THIS */
                                    value={this.state.notificationTitle}
                                    onIonChange={(e) => {
                                        this.setState({ notificationTitle: e.detail.value ? e.detail.value : null });
                                    }}
                                />

                                <IonInput
                                    placeholder="Body" /* TO LOCALIZE THIS */
                                    value={this.state.notificationBody}
                                    onIonChange={(e) => {
                                        this.setState({ notificationBody: e.detail.value ? e.detail.value : null });
                                    }}
                                />

                                <IonInput
                                    placeholder="Call to action url" /* TO LOCALIZE THIS */
                                    value={this.state.notificationCallToActionUrl}
                                    onIonChange={(e) => {
                                        this.setState({ notificationCallToActionUrl: e.detail.value ? e.detail.value : null });
                                    }}
                                />

                                <div className="maestroHomeCardCenteredButtonDiv">
                                    <IonButton
                                        disabled={
                                            !this.state.notificationTitle ||
                                            !this.state.notificationBody
                                        }
                                        onClick={() => {
                                            const sendMaestroUserNotification = httpsCallable(functions, "sendMaestroUserNotification-sendMaestroUserNotification");
                                            sendMaestroUserNotification({
                                                notificationUserUuid: this.state.notificationUserUuid,
                                                notificationTitle: this.state.notificationTitle,
                                                notificationBody: this.state.notificationBody,
                                                notificationCallToActionUrl: this.state.notificationCallToActionUrl,
                                            })
                                                .then(data => {
                                                    console.log(data.data);
                                                    if (!(data.data as any).error) {
                                                        this.setState({ showNotificationSuccessToast: true });
                                                    }
                                                })
                                                .catch(err => {
                                                    console.log("[Maestro home] error calling company data update:", err);
                                                })

                                        }}
                                    >
                                        Invia {/* TO LOCALIZE THIS */}
                                    </IonButton>
                                </div>
                            </IonCardContent>
                        </IonCard>


                        <IonCard>
                            <IonCardHeader>
                                <IonCardTitle>Assegna licenza</IonCardTitle> {/* TO LOCALIZE THIS */}
                            </IonCardHeader>

                            <IonCardContent>

                                <IonInput
                                    placeholder={`Uuid dell'utente a cui assegnare la licenza`}
                                    value={this.state.licenseUserUuid}
                                    onIonChange={e => {
                                        this.setState({ licenseUserUuid: e.detail.value ? String(e.detail.value).trim() : null });
                                    }}
                                />

                                <IonSelect
                                    /* value={this.state.notificationFcm} */
                                    placeholder="Seleziona tipologia di licenza" /* TO LOCALIZE THIS */
                                    okText="Okay"
                                    cancelText="Dismiss"
                                    value={this.state.licenseType}
                                    onIonChange={e => {
                                        this.setState({ licenseType: e.detail.value ? Number(e.detail.value) : null });
                                    }}
                                >
                                    <IonSelectOption
                                        value={-1}
                                    >
                                        Prima settimana gratuita
                                    </IonSelectOption>
                                    <IonSelectOption
                                        value={3}
                                    >
                                        Gratuita assegnata dal team estro (✨)
                                    </IonSelectOption>
                                    <IonSelectOption
                                        value={4}
                                    >
                                        Bonifico
                                    </IonSelectOption>

                                </IonSelect>

                                <IonDatetime
                                    placeholder='Expiry date'
                                    pickerFormat="DD-MMMM-YYYY orario HH:mm"
                                    max="2025-01-01"
                                    min="2022-02-01"
                                    value={this.state.licenseExpiryDatestring}
                                    onIonChange={e => {
                                        this.setState({ licenseExpiryDatestring: e.detail.value ? e.detail.value : null });

                                        if (e.detail.value) {
                                            this.setState({ licenseExpiryTimestamp: Math.floor((new Date(e.detail.value)).getTime() / 1000) })
                                        }
                                        else {
                                            this.setState({ licenseExpiryTimestamp: null });
                                        }

                                    }}
                                />

                                <div className="maestroHomeCardCenteredButtonDiv">
                                    <IonButton
                                        disabled={
                                            !this.state.licenseUserUuid ||
                                            !this.state.licenseType ||
                                            !this.state.licenseExpiryTimestamp
                                        }
                                        onClick={() => {
                                            const assignMaestroLicense = httpsCallable(functions, "assignMaestroLicense-assignMaestroLicense");
                                            assignMaestroLicense({
                                                userUuid: this.state.licenseUserUuid,
                                                licenseType: this.state.licenseType,
                                                expiryTimestamp: this.state.licenseExpiryTimestamp,
                                            })
                                                .then(data => {
                                                    console.log(data.data);
                                                    if (!(data.data as any).error) {
                                                        this.setState({ showLicenseSuccessToast: true });
                                                    }
                                                })
                                                .catch(err => {
                                                    console.log("[Maestro home] error calling company data update:", err);
                                                })
                                        }}
                                    >
                                        Assegna licenza {/* TO LOCALIZE THIS */}
                                    </IonButton>
                                </div>
                            </IonCardContent>
                        </IonCard>

                    </IonGrid>
                </IonContent>
                <TabBar />

                <IonToast
                    isOpen={this.state.showNotificationSuccessToast}
                    message="Notifica inviata ✅"
                    onDidDismiss={() => {
                        this.setState({ showNotificationSuccessToast: false });
                    }}
                    duration={1000}
                />

                <IonToast
                    isOpen={this.state.showLicenseSuccessToast}
                    message="Licenza assegnata 📜✅"
                    onDidDismiss={() => {
                        this.setState({
                            showLicenseSuccessToast: false,
                            licenseUserUuid: null,
                            licenseType: null,
                            licenseExpiryDatestring: null,
                            licenseExpiryTimestamp: null,
                        });
                    }}
                    duration={1000}
                />
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaestroHome);