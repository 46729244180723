import { IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonItem, IonList, IonPage, IonPopover, IonRow, IonSpinner, IonTitle, IonToolbar } from '@ionic/react';
import { get, ref } from 'firebase/database';
import { httpsCallable } from 'firebase/functions';
import React from 'react';
import { connect } from 'react-redux';
import { database, functions } from '../../../common/firebase';
import { fromQueryToObjOfParams } from '../../../common/queryUtils';
import TabBar from '../../../common/TabBar/TabBar';
import { fromMaestroProductIdToProductName } from '../store/services';
import { action2string, step2string } from '../utils/convertTutorialCodesToString';
import { MaestroUserInfo } from '../utils/maestroTypes';
import "./MaestroHome.css";

interface TutorialLogs {
    tutorialId: string,
    logs: Logs[]
}

interface Logs {
    action: number,
    step: number,
    timestamp: number,
}

type Props = {
    history: any,
};

type State = {

    userInfo: MaestroUserInfo | null,
    dateDescending: boolean,
    tutorialsLogs: TutorialLogs[],
    showAccessesPopover: boolean,

    isLoadingUserData: boolean,
};

class MaestroUser extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        this.state = {

            userInfo: null,
            dateDescending: true,
            tutorialsLogs: [],
            showAccessesPopover: false,

            isLoadingUserData: false,
        }
    }

    componentDidMount() {
        const userUuid = fromQueryToObjOfParams(this.props.history.location.search as string)["userUuid"];
        this.setState({ isLoadingUserData: true });
        const getMaestroUser = httpsCallable(functions, "getMaestroUser-getMaestroUser");
        getMaestroUser({
            userUuid: userUuid
        })
        .then(res => {
            console.log("[MaestroUser] user info:", res.data);
            if((res.data as any).user) {
                this.setState({ userInfo: (res.data as any).user });
            }
            else {
                console.error("[MaestroUser] error returned by getMaestroUser cloud function:", res.data);
            }
            this.setState({ isLoadingUserData: false });
        })
        .catch(err => {
            console.error("[MaestroUser] error calling getMaestroUser cloud function:", err);
            this.setState({ isLoadingUserData: false });
        });

        //TODO GET TUTORIALS INFO

        const getMaestroUserTutorials = httpsCallable(functions, "getMaestroUserTutorials-getMaestroUserTutorials");
        getMaestroUserTutorials({
            userUuid: userUuid
        })
        .then(data => {
            console.log(data.data);
            if (data.data)
                this.setState({ tutorialsLogs: (data.data as any).logs })
            // let users = (data.data as any).users
            // if ((data.data as any).users) {
            //     (data.data as any).users.forEach((user: any) => {
            //         user.accesses.sort((a: any, b: any) => { return b - a })
            //         user.lastAccess = user.accesses[0];
            //     })
            //     this.sortUserList(users)
            //     this.setState({ usersInfo: (data.data as any).users });

            // }
        })
        .catch(err => {
            console.log("[Maestro home] error calling get user to contact:", err);
        });
    }

    render() {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot='start'>
                            <IonBackButton defaultHref='/' />
                        </IonButtons>
                        <IonTitle>Utente Maestro</IonTitle>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <IonGrid className="maestroHomeMainGrid">
                        {
                            !this.state.isLoadingUserData &&
                            <>
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>Utente</IonCardTitle> {/* TO LOCALIZE THIS */}
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <div
                                        
                                            className="maestroHomeUserInfoRow"
                                        >
                                            <p className="maestroHomeUserEmailParagraph">{this.state.userInfo?.email}</p>
                                            <p className="maestroHomeUserInfoParagraph">{this.state.userInfo?.firstName ? this.state.userInfo?.firstName : "-"}</p>
                                            <p className="maestroHomeUserInfoParagraph">{this.state.userInfo?.lastName ? this.state.userInfo?.lastName : "-"}</p>
                                            <p className="maestroHomeUserInfoParagraph">{this.state.userInfo?.role ? this.state.userInfo?.role : "-"}</p>
                                        </div>

                                        {
                                            this.state.userInfo?.products.map(product => { return (
                                                <div
                                                    key={product.uuid}
                                                    className="organizationDetailProductItemDiv"
                                                >
                                                    <p className="organizationDetailProductItemName">
                                                        {fromMaestroProductIdToProductName(product.productId)}
                                                    </p>

                                                    <p>
                                                        Ottenuta il: {(new Date(product.startTimestamp*1000)).toLocaleDateString()} alle {(new Date(product.startTimestamp*1000)).toLocaleTimeString()}.
                                                    </p>
                                                    <p>
                                                        Scadenza il: {(new Date(product.expiryTimestamp*1000)).toLocaleDateString()} alle {(new Date(product.expiryTimestamp*1000)).toLocaleTimeString()}.
                                                    </p>
                                                </div>
                                            )})
                                        }
                                    </IonCardContent>
                                </IonCard>

                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>Accessi</IonCardTitle> {/* TO LOCALIZE THIS */}
                                    </IonCardHeader>

                                    <IonCardContent>
                                        <div className="maestroHomeuserInfoDiv">
                                            {
                                                this.state.userInfo?.accesses.map((access) => { return (
                                                    <div
                                                        key={access.timestamp}
                                                        className="maestroHomeUserInfoRow"
                                                    >
                                                        <p className="maestroHomeUserInfoParagraph">{(new Date(access.timestamp*1000)).toUTCString()}</p>
                                                        <p className="maestroHomeUserEmailParagraph">{access.device?.browser ? access.device?.browser : "-"}</p>
                                                        <p className="maestroHomeUserInfoParagraph">{access.device?.mobile ? access.device?.mobile : "-"}</p>
                                                        <p className="maestroHomeUserInfoParagraph">{access.device?.os ? access.device?.os : "-"}</p>
                                                        <p className="maestroHomeUserInfoParagraph">{access.device?.type ? access.device?.type : "-"}</p>
                                                    </div>
                                                )})
                                            }
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                            
                                <IonCard>
                                    <IonCardHeader>
                                        <IonCardTitle>Tutorial</IonCardTitle> {/* TO LOCALIZE THIS */}
                                    </IonCardHeader>

                                    <IonCardContent>
                                        <div className="maestroHomeuserInfoDiv">
                                            {
                                                this.state.tutorialsLogs.map((tutorial, i) => { return (
                                                    <div key={`${tutorial.tutorialId}-i${i}`}>
                                                        {
                                                            tutorial.logs.map((log,k) => { return (
                                                                <div
                                                                    key={log.timestamp}
                                                                    className="maestroHomeUserInfoRow"
                                                                >
                                                                    <p className="maestroHomeUserInfoParagraph">{(new Date(log.timestamp*1000)).toUTCString()}</p>
                                                                    <p className="maestroHomeUserEmailParagraph">{log.action != null ? action2string(log.action) : "-"}</p>
                                                                    <p className="maestroHomeUserInfoParagraph">{log.step ? step2string(log.step) : "-"}</p>
                                                                </div>
                                                            )})
                                                        }
                                                    </div>           
                                                )})
                                            }
                                        </div>
                                    </IonCardContent>
                                </IonCard>
                            </>
                        }
                        {
                            this.state.isLoadingUserData &&
                            <div>
                                <IonSpinner />
                            </div>
                        }
                    </IonGrid>
                </IonContent>
                <TabBar />
            </IonPage>

        );
    }
}

const mapStateToProps = (state: any) => {
    return {
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaestroUser);